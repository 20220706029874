<template>
  <div>
    <b-table
      :items="items"
      :fields="computedFields"
      responsive 
      striped
      bordered
      hover
      outlined
      class="rounded-table"
    >
      <template #thead-top="data">
        <b-tr>
          <b-th variant="secondary" colspan="2" class="text-center"
            >Soluções</b-th
          >
          <b-th variant="primary" colspan="2" class="text-center"
            >Entrevista Paga</b-th
          >
          <b-th variant="danger" colspan="2" class="text-center"
            >Assinatura</b-th
          >
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import { toCurrency } from "@/helpers/converters.js";

export default {
  name: "CommissionManagementTotalizer",
  props: {
    totalizer: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    computedFields() {
      return [
        { key: "solution_received", label: "Valor", class: "text-center" },
        { key: "solution_qtd", label: "Qtde", class: "text-center" },
        { key: "ep_received", label: "Valor", class: "text-center" },
        { key: "ep_qtd", label: "Qtde", class: "text-center" },
        { key: "sub_received", label: "Valor", class: "text-center" },
        { key: "sub_qtd", label: "Qtde", class: "text-center" },
      ];
    },
  },
  created() {
    this.fillData();
  },
  watch: {
    totalizer: {
      handler(newVal) {
        this.fillData();
      },
      deep: true,
    },
  },
  methods: {
    fillData() {
      const defaultTotalizer = {
        solution_received: 0,
        solution_qtd: 0,
        ep_received: 0,
        ep_qtd: 0,
        sub_received: 0,
        sub_qtd: 0,
      };

      const data = this.totalizer || defaultTotalizer;

      this.items = [
        {
          solution_received: toCurrency(data.solution_received || 0),
          solution_qtd: data.solution_qtd || 0,
          ep_received: toCurrency(data.ep_received || 0),
          ep_qtd: data.ep_qtd || 0,
          sub_received: toCurrency(data.sub_received || 0),
          sub_qtd: data.sub_qtd || 0,
        },
      ];
    },
  },
};
</script>

<style scoped>
.text-center th {
  text-align: center;
}
.rounded-table {
  border-radius: 5px;
  /* overflow: hidden; */
  overflow-x: auto;
}
</style>
